import { css } from "@emotion/css";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from '../toast';
import CropImage from "./CropImage";


const divFormCsss = css({
   '& > *': {
      margin: '10px auto !important'
   }
});


class AddEvent extends Component {


   submit = async () => {

      const txtName = document.getElementById('txt-name');
      const txtLocation = document.getElementById('txt-location');
      const txtDate = document.getElementById('txt-date');
      const txtTime = document.getElementById('txt-time');
      const divImage = document.getElementById('div-image');

      const name = txtName.value;
      const location = txtLocation.value;
      const date = txtDate.value;
      const time = txtTime.value;
      const image = divImage.value;

      if (!name) {
         errorToast('Provide event name');
         return txtName.focus();
      }

      if (!location) {
         errorToast('Provide event location');
         return txtLocation.focus();
      }

      if (!date) {
         errorToast('Provide event date');
         return txtDate.focus();
      }

      if (!time) {
         errorToast('Provide event name');
         return txtTime.focus();
      }

      if (!image) {
         errorToast('Provide event image');
         return divImage.focus();
      }

      const data = {
         location,
         name,
         time: new Date(`${date} ${time}`),
         image,
      }


      try {
         showLoading();

         const res = await request.post('/api/events', data);
         const event = res.data;

         this.props.close(event);
      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   
   }

   render() {
      return <Dialog open={this.props.open}>

         <DialogTitle>ADD EVENT</DialogTitle>

         <DialogContent>
            <div className={divFormCsss}>
               <TextField
                  fullWidth
                  label="Event name"
                  variant="standard"
                  size="small"
                  id="txt-name"
               />

               <TextField
                  fullWidth
                  label="Location"
                  variant="standard"
                  size="small"
                  id="txt-location"
               />

               <TextField
                  fullWidth
                  label="Date"
                  variant="standard"
                  size="small"
                  id="txt-date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
               />

               <TextField
                  fullWidth
                  label="Time"
                  variant="standard"
                  size="small"
                  id="txt-time"
                  type="time"
                  InputLabelProps={{ shrink: true }}
               />


               <div>
                  <FormLabel>Event Image</FormLabel>
                  <CropImage
                     aspectRatio={16 / 9}
                     id="div-image"
                  />
               </div>
                  

            </div>
         </DialogContent>

         <DialogActions>
            <Button variant="contained" onClick={this.submit}>
               SAVE
            </Button>
            <Button onClick={this.props.close}>
               CANCEL
            </Button>
         </DialogActions>
      </Dialog>
   }
}

export default AddEvent;