import Component from "@xavisoft/react-component";
import AppBar from '@mui/material/AppBar';
import logo from '../media/img/logos/logo.png';
import { css } from "@emotion/css";
import {  Button, Grid, Hidden } from "@mui/material";
import { HashLink as Link } from 'react-router-hash-link';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MobileNav from "./MobileNav";
import { connect } from "react-redux";
import ZimFlagIcon from "./ZimFlagIcon";




const navbarStyle = css({
   background: 'rgba(0, 0, 0, 0.9) !important',
   fontFamily: 'Arial',
});


const links = [
   {
      caption: 'Home',
      to: '/'
   },
   {
      caption: 'Organizations',
      to: '/partners'
   },
   {
      caption: 'Contact Us',
      to: '/contact-us'
   },
   {
      caption: 'About',
      // onClick: scrollToAbout,
      to: '/#about'
   },
   {
      caption: 'Upload certificate',
      // onClick: scrollToAbout,
      to: '/upload-certificate'
   },
]



class Navbar extends Component {

   state = {
      mobileNavOpen: false,
   }

   openMobileNav = () => {
      return this.updateState({ mobileNavOpen: true })
   }

   closeMobileNav = () => {
      return this.updateState({ mobileNavOpen: false })
   }

   setDimensions = () => {
      const navbar = document.getElementById('navbar');
      
      const width = navbar.offsetWidth + 'px';
      const height = navbar.offsetHeight + 'px';

      document.documentElement.style.setProperty('--navbar-width', width);
      document.documentElement.style.setProperty('--navbar-height', height);
   }

   componentWillUnmount() {
      this.resizeOberver.disconnect();
   }

   componentDidMount() {
      const resizeOberver = new window.ResizeObserver(this.setDimensions);
      resizeOberver.observe(document.getElementById('navbar'));
      this.resizeOberver = resizeOberver;

      this.setDimensions();
   }

   render() {


      let jsx;

      if (this.props.currentRoute !== '/dashboard') {
         jsx = <Grid container>

            <Grid item xs={8} sm={6} md={3}>
               <Link 
                  to="/"
                  style={{
                     textDecoration: 'none',
                     color: 'white',
                     fontSize: '16pt'
                  }}
                  className="valign"
               >
                  <img 
                     src={logo} 
                     alt=""
                     style={{
                        height: '32pt',
                        margin: '8pt',
                        marginLeft: '16pt'
                     }}
                  />

                  QualityRights
                  <ZimFlagIcon
                     style={{
                        width: '16pt'
                     }}
                  />
               </Link>
            </Grid>


            <Hidden only={[ 'xs', 'sm' ]}>
               <Grid item md={9}>
                  <div
                     style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 120pt',
                     }}
                     className="fill-container"
                  >
                     <div className="vh-align">
                        {
                           links.map(link => {

                              const color = link.to === this.props.currentRoute ? 'yellow' : 'white';

                              return <Link
                                 style={{
                                    display: 'inline-block',
                                    color,
                                    textTransform: 'uppercase',
                                    // textUnderlineOffset: '4pt',
                                    textDecoration: 'none',
                                    margin: 'auto',
                                    fontSize: '11pt',
                                 }}
                                 to={link.to}
                                 onClick={link.onClick}
                                 key={link.caption}
                              >{link.caption}</Link>
                           })
                        }
                     </div>
                     
                     <div className="v-align" style={{ padding: '8pt'}}>
                        
                     </div>
                  </div>
                     
               </Grid>
            </Hidden>

            <Hidden mdUp only={[ 'md', 'lg', 'xl' ]}>
               <Grid item xs={4}>
                  <div
                     style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'right',
                     }}
                     className="fill-container"
                  >
                     <Button onClick={this.openMobileNav}>
                        <MenuOpenIcon />
                     </Button>

                     <MobileNav
                        open={this.state.mobileNavOpen}
                        close={this.closeMobileNav}
                        links={links}
                     />
                  </div>
               </Grid>
            </Hidden>
         </Grid>
      }

      return <AppBar id="navbar" className={navbarStyle}>
         {jsx}
      </AppBar>
   }
}

function mapStateToProps(state) {
   const { currentRoute } = state;
   return { currentRoute }
}

export default connect(mapStateToProps)(Navbar);