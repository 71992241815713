
import GeneratePdfReport from '../components/GeneratePdfReport';
import Page from './Page';

class Test extends Page {

	
   _render() {
		return <div>
			<GeneratePdfReport open />
		</div>
   }
}


export default Test;