import { Grid } from "@mui/material";
import Component from "@xavisoft/react-component";
import request from "../request";
import Event from "./Event";


class Events extends Component {

   state = {
      eventsFetched: false,
   }


   fetchData = async () => {

      try {

         const res = await request.get('/api/events');

         const now = Date.now();

         const upcoming_events = [];
         const past_events = [];

         res.data.forEach(event => {
            
            const date = new Date(event.time);

            if (date.getTime() < now)
               past_events.push(event);
            else
               upcoming_events.push(event);
            
         });

         await this.updateState({ eventsFetched: true, past_events, upcoming_events });

      } catch (err) {
         console.error(err);
      }
   }


   componentDidMount() {
      this.fetchData();
   }

   render() {

      if (!this.state.eventsFetched)
         return;

      return <section>

         <div style={{ margin: '16pt' }}>
            
            <h2>EVENTS</h2>

            <EventList events={this.state.upcoming_events} title="UPCOMING" />
            <EventList events={this.state.past_events} title="PAST" />
            
         </div>
      </section>
   }

}


function EventList(props) {


   let eventsJSX;

   if (props.events.length > 0) {
      eventsJSX = <Grid container spacing={2}>
         {
            props.events.map(event => {
               return <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Event
                     name={event.name}
                     location={event.location}
                     date={event.date}
                     time={event.time}
                     image={event.image}
                  />
               </Grid>
            })
         }
      </Grid>
   } else {
      eventsJSX = <p className="grey-text">No events</p>
   }

   return <>
      <h3 className="grey-text">{props.title}</h3>
      {eventsJSX}
   </>
}

export default Events;