import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function WhatsThisAbout(props) {

   return <Dialog open={props.open}>
      <DialogTitle>ABOUT THE COURSE</DialogTitle>
      
      <DialogContent style={{ fontFamily: 'Arial' }}>
         <p>
         This Mental Health course sponsored by the World Health Organization (WHO), is designed to equip you with the capacity to understand and promote the human rights and recovery of persons living with mental health conditions.
         </p>

         <h3>Course Outline</h3>
         <p>
            It is a 16-hour interactive course divided into six modules:
            <List>
               <ListItem><ChevronRightIcon /> Understanding human rights</ListItem>
               <ListItem><ChevronRightIcon />Promoting human rights in mental health</ListItem>
               <ListItem><ChevronRightIcon />Improving mental health and related service environments and promoting community inclusion</ListItem>
               <ListItem><ChevronRightIcon />Realizing recovery and the right to health in mental health and related services</ListItem>
               <ListItem><ChevronRightIcon />Protecting the right to legal capacity in mental health and related services</ListItem>
               <ListItem><ChevronRightIcon />Creating mental health and related services free from coercion, violence and abuse</ListItem>
            </List>
         </p>

         <h3>Recognition</h3>
         <p>
            Each successful participant receives a certificate, and recognized as a coach by the World Health Organization (WHO).
         </p>

         <h3>Target</h3>
         <p>
            This course is free and open to all interested in improving mental health including care partners, persons living with some mental health condition in Ghana.
         </p><p>
            This an opportunity for all advocates, nurses, teachers, doctors, psychiatrists, social welfare workers, students, security personel who may require it as a Continuing Professional Development (CPD).
         </p>
      </DialogContent>

      <DialogActions>
         <Button onClick={props.close}>
            CLOSE
         </Button>
      </DialogActions>
   </Dialog>
}

export default WhatsThisAbout;