import { Button, Grid, Hidden, TextField } from "@mui/material";
import Page from "./Page";
import { connect } from 'react-redux';
import { css } from '@emotion/css';
import { errorToast } from "../toast";
import swal from 'sweetalert';
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import MetaTags from 'react-meta-tags';


const divFormCss = css({
   padding: '24pt',
   '& > *': {
      margin: '8pt auto !important'
   }
});


class Contact extends Page {

   submit = async () => {
      const txtName = document.getElementById('txt-name');
      const txtEmail = document.getElementById('txt-email');
      const txtPhone = document.getElementById('txt-phone');
      const txtSubject = document.getElementById('txt-subject');
      const txtMessage = document.getElementById('txt-message');

      const name = txtName.value;
      const email = txtEmail.value || undefined;
      const phone = txtPhone.value || undefined;
      const subject = txtSubject.value;
      const message = txtMessage.value;

      if (!name) {
         errorToast('Your full name is required');
         return txtName.focus();
      }

      if (!email && !phone) {
         errorToast('Provide either your email or your phone');
         return txtEmail.focus();
      }
      
      if (!message) {
         errorToast('Message is required');
         return txtMessage.focus();
      }

      const data = {
         name,
         email,
         phone,
         subject,
         message
      }

      try {

         showLoading();

         await request.post('/api/queries', data);
         swal('Your message has been sent. We will get back to you as soon as we can');

         [ txtName, txtEmail, txtPhone, txtSubject, txtMessage ].forEach(textbox => {
            textbox.value = '';
         });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }


   }

   _render() {

      let divFormParentClass;
      const divFormParentStyle = {};
      const divFormStyle = {}

      switch (this.props.device.size) {

         case 'md':
         case 'lg':
         case 'xl':

            divFormStyle.width = 400;
            divFormParentStyle.height = 'calc(var(--window-height) - var(--navbar-height))';
            divFormParentClass = 'vh-align';

            break;
         
         default:
            break;
         
      }

      return <>

         <MetaTags>
            <title>Contact Us | Quality Rights Zimbabwe</title>
            <meta name="description" content="Fill in the form below. We will get back to you as soon as we can." />
         </MetaTags>
         
         <Grid container>

            <Hidden smDown>
               <Grid md={6}>
                  <div className="fill-container primary-bg">

                  </div>
               </Grid>

            </Hidden>

            <Grid xs={12} md={6}>
               <div className={divFormParentClass} style={divFormParentStyle}>
                  <div className={divFormCss} style={divFormStyle}>

                     <h4>CONTACT US</h4>

                     <TextField
                        fullWidth
                        label="Full Name"
                        variant="standard"
                        id="txt-name"
                        size="small"
                     />

                     <TextField
                        fullWidth
                        label="Email"
                        variant="standard"
                        id="txt-email"
                        size="small"
                     />


                     <TextField
                        fullWidth
                        label="Phone number"
                        variant="standard"
                        id="txt-phone"
                        size="small"
                     />


                     <TextField
                        fullWidth
                        label="Subject"
                        variant="standard"
                        id="txt-subject"
                        size="small"
                     />


                     <TextField
                        fullWidth
                        label="Message"
                        variant="standard"
                        id="txt-message"
                        size="small"
                        multiline
                     />

                     <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        className="primary-bg"
                        onClick={this.submit}
                     >
                        SUBMIT
                     </Button>

                  </div>
               </div>
            </Grid>
            
         </Grid>
      </>
   }
}



function mapStateToProps(state) {
   return {
      device: state.device
   }
}

export default connect(mapStateToProps)(Contact);