import { css } from "@emotion/css";
import { Button, Grid, MenuItem, Step, StepContent, StepLabel, Stepper, TextField } from "@mui/material";
import { MetaTags } from "react-meta-tags";
import swal from "sweetalert";
import CertificateLinkInstructions from "../components/CertificateLinkInstructions";
// import Base64FileInput from "../components/Base64FileInput";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from "../toast";
import Page from "./Page";
import { connect } from "react-redux";
import { fetchOrganizations } from "../organizations";
import { PROVINCES } from "../constants";


const divFormCss = css({
   '& > *': {
      margin: '10px auto !important'
   }
});

const whereDidYouHearAboutUs = {
   whatsapp: 'WhatsApp',
   facebook: 'Facebook',
   word_of_mouth: 'Word Of Mouth',
   blog: 'Blog',
   event: 'In-person event',
   other: 'Other'
};


const professions = {
   'mental': 'I work in a psychiatric unit',
   'healthcare': 'I work in a general health unit',
   'non_medical' :'I work in a non-medical unit',
   'student': 'I am a student',
   'no_profession': 'I am neither employed nor studying'
}

const fields = {
   'mental': 'I am specializing in mental health studies',
   'healthcare': 'I am specializing in other medical studies',
   'non_medical' :'I am undertaking non medical studies'
}

class UnconnectedUploadCertificate extends Page {


   state = {
      certificateLinkInstructionsModalOpen: false,
      step: 0,
      values: {}
   }

   openCertificateLinkInstructionsModal = () => {
      return this.updateState({ certificateLinkInstructionsModalOpen: true });
   }

   closeCertificateLinkInstructionsModal = () => {
      return this.updateState({ certificateLinkInstructionsModalOpen: false });
   }


   nextStep = () => {
      
      let step = this.state.step;

      switch (step) {

         case 0:

            const txtName = document.getElementById('txt-name');
            const txtSurname = document.getElementById('txt-surname');
            const txtEmail = document.getElementById('txt-email');
            const selProvince = document.getElementById('sel-province');
            const txtPhone = document.getElementById('txt-phone');

            const { name, surname, email, phone, province } = this.state.values;

            if (!name) {
               errorToast('Your name is required')
               return txtName.focus();
            }
      
            if (!surname) {
               errorToast('Your surname is required')
               return txtSurname.focus();
            }

            if (!email) {
               errorToast('Your email is required')
               return txtEmail.focus();
            }
      
            if (!phone) {
               errorToast('Your phone number is required');
               return txtPhone.focus();
            }
      
            if (!province) {
               errorToast('Province is required')
               return selProvince.focus();
            }

            break;

         case 1:

            const selProfession = document.getElementById('sel-profession');

            const { profession } = this.state.values;

            if (!profession) {
               errorToast('Select your profession');
               return selProfession.focus();
            }

            if (profession === 'non_medical') {

               const txtNonMedicalProfession  = document.getElementById('txt-non-medical-profession');
               const { non_medical_profession } = this.state.values;

               if (!non_medical_profession) {
                  errorToast('Provide the field you work in');
                  return txtNonMedicalProfession.focus();
               }

            } else if (profession === 'student') {

               const selField = document.getElementById('sel-field');
               const { field } = this.state.values;

               if (!field) {
                  errorToast('Select your field');
                  return selField.focus();
               }

               if (field === 'non_medical') {

                  const txtNonMedicalField  = document.getElementById('txt-non-medical-field');
                  const { non_medical_field } = this.state.values;

                  if (!non_medical_field) {
                     errorToast('Provide the field you work in');
                     return txtNonMedicalField.focus();
                  }
               }

            }
            break;
         case 2:

            const txtWallet = document.getElementById('txt-wallet');
            const txtLink = document.getElementById('txt-link');
            const selInformant = document.getElementById('sel-informant');
            const txtCoordinator = document.getElementById('txt-coordinator');


            const { wallet, link, informant, coordinator } = this.state.values;

            if (!informant) {
               errorToast('Please tell us where you heard about the e-training')
               return selInformant.focus();
            }

            if (informant === 'other') {
               const txtOtherInformant = document.getElementById('txt-other-informant');
               const { other_informant } = this.state.values;

               if (!other_informant) {
                  errorToast('Tell us where you heard about the e-training');
                  return txtOtherInformant.focus();
               }
            }

            if (!coordinator) {
               errorToast('Please select the organization that coordinated your training')
               return txtCoordinator.focus();
            }

            if (!wallet) {
               errorToast('Your ecocash number is required')
               return txtWallet.focus();
            }
      
      
            if (!link) {
               errorToast('Provide your certificate link')
               return txtLink.focus();
            }
      
            const regex = /https:\/\/humanrights-etrain-qualityrights.coorpacademy.com\/users\/[0-9A-Fa-f]+\/[\s\S]*.pdf/gi;
      
            if (!regex.test(link)) {
               errorToast('Your link is invalid');
               return txtLink.focus();
            }
            
            this.submit();
            return;

         default:
            return;
      }

      step++

      this.updateState({ step });
   }


   submit = async  () => {

      

      const { name, surname, email, phone, province } = this.state.values;
      const { profession, non_medical_profession, field, non_medical_field } = this.state.values;
      const { wallet, link, informant, other_informant, coordinator } = this.state.values;

      const data = {
         name,
         surname,
         phone,
         email,
         province,
         wallet,
         link,
         coordinator,
      }


      if (informant === 'other') {
         data.informant = `other:${other_informant}`;
      } else {
         data.informant = informant;
      }

      if (profession === 'non_medical') {
         data.profession = non_medical_profession;
      } else if (profession === 'student') {
         if (field === 'non_medical')
            data.profession = non_medical_field;
         else
            data.profession = field;
      } else {
         data.profession = profession;
      }

      data.is_student = profession === 'student';


      try {
         
         showLoading();

         await request.post('/api/certificates', data);
      
         swal('Your certificate has been submitted successfully');

         // reset form
         await this.updateState({ values: {}, step: 0 });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }


   }

   componentDidMount() {

      super.componentDidMount();

      if (!this.props.organizations)
         fetchOrganizations();
   }

   _render() {

      const { organizations } = this.props;

      if (!organizations) {
         return <div className="page vh-align">
            <div>
               <p style={{ color: 'gray', fontSize: 20, fontFamily: 'Arial', }}>
                  Failed to load some data. Please try again.
               </p>

               <Button onClick={fetchOrganizations} variant="outlined">
                  RETRY
               </Button>
            </div>
         </div>
      }
 
      return <>
         
         <MetaTags>
            <title>Upload Certificate | Quality Rights Zimbabwe</title>
            <meta name="description" content="Provide a link to your Quality Rights e-training and claim USD30.00" />
         </MetaTags>

         <Grid container>

            <Grid item xs={12} sm={1} md={4}></Grid>

            <Grid item xs={12} sm={10} md={4}>

               <div style={{ padding: '20px' }}>
                  <h1>UPLOAD CERTIFICATE</h1>

                  <Stepper orientation="vertical" activeStep={this.state.step}>
                     <Step>
                        <StepLabel>Personal Info</StepLabel>
                        <StepContent>
                           <div className={divFormCss}>

                              <TextField
                                 fullWidth
                                 label="First name(s)"
                                 size="small"
                                 variant="standard"
                                 id="txt-name"
                                 value={this.state.values.name}
                                 onChange={this.onChangeHandlerGenerator('name')}
                              />

                              <TextField
                                 fullWidth
                                 label="Surname"
                                 size="small"
                                 variant="standard"
                                 id="txt-surname"value={this.state.values.surname}
                                 onChange={this.onChangeHandlerGenerator('surname')}
                              />

                              <TextField
                                 fullWidth
                                 label="Email address"
                                 size="small"
                                 variant="standard"
                                 id="txt-email"
                                 value={this.state.values.email}
                                 onChange={this.onChangeHandlerGenerator('email')}
                              />
                              
                              <TextField
                                 fullWidth
                                 label="Phone number"
                                 size="small"
                                 variant="standard"
                                 id="txt-phone"
                                 value={this.state.values.phone}
                                 onChange={this.onChangeHandlerGenerator('phone')}
                              />


                              <TextField
                                 fullWidth
                                 label="Where do you stay"
                                 size="small"
                                 variant="standard"
                                 select
                                 inputProps={{
                                    id: 'sel-province'
                                 }}
                                 value={this.state.values.province}
                                 onChange={this.onChangeHandlerGenerator('province')}
                              >
                                 {
                                    PROVINCES.map(province => {
                                       return <MenuItem value={province}>{province}</MenuItem>
                                    })
                                 }
                              </TextField>


                              <div className="right-align">
                                 <Button variant="contained" onClick={this.nextStep}>
                                    NEXT
                                 </Button>
                              </div>

                           </div>
                        </StepContent>
                     </Step>

                     <Step>
                        <StepLabel>Professional Info</StepLabel>
                        <StepContent>
                           <div className={divFormCss}>

                              <TextField
                                 fullWidth
                                 label="Which field do you work in?"
                                 size="small"
                                 variant="standard"
                                 id="sel-profession"
                                 value={this.state.values.profession}
                                 onChange={this.onChangeHandlerGenerator('profession')}
                                 select
                              >
                                 {
                                    Object.keys(professions).map(key => {
                                       return <MenuItem value={key}>{professions[key]}</MenuItem>
                                    })
                                 }
                              </TextField>


                              {
                                 (() => {
                                    
                                    const { profession } = this.state.values;

                                    if (profession === 'non_medical') {

                                       return <TextField
                                          fullWidth
                                          label="Name of your field"
                                          size="small"
                                          variant="standard"
                                          id="txt-non-medical-profession"
                                          value={this.state.values.non_medical_profession}
                                          onChange={this.onChangeHandlerGenerator('non_medical_profession')}
                                       />

                                    } else if (profession === 'student') {

                                       return <>
                                          <TextField
                                             fullWidth
                                             label="Name of your field of study"
                                             size="small"
                                             variant="standard"
                                             id="sel-field"
                                             value={this.state.values.field}
                                             onChange={this.onChangeHandlerGenerator('field')}
                                             select
                                          >
                                             {
                                                Object.keys(fields).map(key => {
                                                   return <MenuItem value={key}>{fields[key]}</MenuItem>
                                                })
                                             }
                                          </TextField>

                                          {
                                             (() => {
                                                if (this.state.values.field === 'non_medical') {
                                                   return <TextField
                                                      fullWidth
                                                      label="Name of your field"
                                                      size="small"
                                                      variant="standard"
                                                      id="txt-non-medical-field"
                                                      value={this.state.values.non_medical_field}
                                                      onChange={this.onChangeHandlerGenerator('non_medical_field')}
                                                   />
                                                }
                                             })()
                                          }
                                       </>
                                    }
                                 })()
                              }

                              <div className="right-align">
                                 <Button variant="contained" onClick={this.nextStep}>
                                    NEXT
                                 </Button>
                              </div>

                           </div>                           
                        </StepContent>
                     </Step>

                     <Step>
                        <StepLabel>Training info</StepLabel>

                        <StepContent>
                           <div className={divFormCss}>

         
                              <TextField
                                 fullWidth
                                 label="How did you hear about the e-training?"
                                 size="small"
                                 variant="standard"
                                 id="sel-informant"
                                 value={this.state.values.informant}
                                 onChange={this.onChangeHandlerGenerator('informant')}
                                 select
                              >
                                 {
                                    Object.keys(whereDidYouHearAboutUs).map(key => {
                                       return <MenuItem value={key}>{whereDidYouHearAboutUs[key]}</MenuItem>
                                    })
                                 }
                              </TextField>


                              {
                                 (() => {
                                    if (this.state.values.informant === 'other') {
                                       return <TextField
                                          fullWidth
                                          label="Specify"
                                          size="small"
                                          variant="standard"
                                          id="txt-other-informant"
                                          value={this.state.values.other_informant}
                                          onChange={this.onChangeHandlerGenerator('other_informant')}
                                       />
                                    }
                                 })()
                              }

                              <TextField
                                 fullWidth
                                 label="Which organization coordinated the training?"
                                 size="small"
                                 variant="standard"
                                 id="txt-coordinator"
                                 value={this.state.values.coordinator}
                                 onChange={this.onChangeHandlerGenerator('coordinator')}
                                 select
                              >
                                 {
                                    organizations.map(organization => {
                                       const { name } = organization
                                       return <MenuItem value={name}>
                                          {name}
                                       </MenuItem>
                                    })
                                 }
                              </TextField>
                           

                              <TextField
                                 fullWidth
                                 label="Ecocash number or Nostro A/C"
                                 size="small"
                                 variant="standard"
                                 id="txt-wallet"
                                 value={this.state.values.wallet}
                                 onChange={this.onChangeHandlerGenerator('wallet')}
                              />

                              <TextField
                                 fullWidth
                                 label="Certificate link"
                                 size="small"
                                 variant="standard"
                                 id="txt-link"
                                 value={this.state.values.link}
                                 onChange={this.onChangeHandlerGenerator('link')}
                              />

                              <span
                                 style={{
                                    color: 'red',
                                    fontSize: 10,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                 }}
                                 onClick={this.openCertificateLinkInstructionsModal}
                              >
                                 Where do I find the link?
                              </span>

                              

                              <Button
                                 onClick={this.nextStep}
                                 variant="contained"
                                 fullWidth
                                 size="large"
                              >
                                 SUBMIT
                              </Button>


                              <CertificateLinkInstructions
                                 open={this.state.certificateLinkInstructionsModalOpen}
                                 close={this.closeCertificateLinkInstructionsModal}
                              />


                           </div>
                        </StepContent>
                     </Step>

                  </Stepper>
               </div>
            </Grid>

            <Grid item xs={12} sm={1} md={4}></Grid>
         </Grid>
      </>
            
   }
}

const mapStateToProps = state => {
   const { organizations } = state || {};
   return { organizations };
}

const UploadCertificate = connect(mapStateToProps)(UnconnectedUploadCertificate);
export default UploadCertificate;