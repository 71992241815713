
const PROVINCES = [
   'Masvingo',
   'Harare Metropolitan',
   'Bulawayo Metropolitan',
   'Mashonaland East',
   'Mashonaland West',
   'Mashonaland Central',
   'Matebeleland South',
   'Matebeleland North',
   'Manicaland',
   'Midlands',
].sort();


export {
   PROVINCES
}