
import reducer from "./reducer";
import { createStore } from 'redux'


const initialState = {
   device: {
      mobile: true,
      size: "xs",
      type: "phone"
   },
   currentRoute: '/',
   organizations: null,
}

const store = createStore(
   reducer,
   initialState
);

export default store;