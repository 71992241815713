
import './App.css';
import './animate.css';
import AppWrapper, { Route } from '@xavisoft/app-wrapper';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import { Provider } from 'react-redux';
import store from './store';
import { init as initDeviceInfo } from './device';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import UploadCertificate from './pages/UploadCertificate';
import Test from './pages/Test';
import Footer from './components/Footer';
import PageNotFound from './pages/PageNotFound';
import Component from '@xavisoft/react-component';


Component.prototype.updateState = function (updates={}) {
	return new Promise(resolve => {
		const state = this.state || {};
		const newState = { ...state, ...updates }
		this.setState(newState, resolve);
	})
}


function setDimensions() {
	
	const width = window.innerWidth + 'px';
	const height = window.innerHeight + 'px';

	document.documentElement.style.setProperty('--window-width', width);
	document.documentElement.style.setProperty('--window-height', height);

}

window.addEventListener('resize', setDimensions);
setDimensions();


initDeviceInfo();


function App() {


	// if (window.location.href.indexOf('.org.zw') > 0) {
	// 	return (
	// 		<Provider store={store}>
	// 			<AppWrapper>
	
	// 				<Navbar />

	// 				<Route path="*" component={ComingSoon} />
	
	// 				<Footer />
	
	// 			</AppWrapper>
	// 		</Provider>
	// 	); 
	// }

	return (
		<Provider store={store}>
			<AppWrapper>

				<Navbar />

				<Route path="/" component={Home} />
				<Route path="/partners" component={Partners} />
				<Route path="/contact" component={Contact} />
				<Route path="/contact-us" component={Contact} />
				<Route path="/login" component={Login} />
				<Route path="/dashboard" component={Dashboard} />
				<Route path="/upload-certificate" component={UploadCertificate} />
				<Route path="/test" component={Test} />
				<Route path="*" component={PageNotFound} />

				<Footer />

			</AppWrapper>
		</Provider>
	);
}

export default App;