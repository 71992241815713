import { Grid, Hidden } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LazyloadImage from "./LazyloadImage";
import pamumvuriLogo from '../media/img/logos/pamumvuri.png';
import whoLogo from '../media/img/logos/who.png';
import cbmLogo from '../media/img/logos/cbm.jpeg';
// import mohccLogo from '../media/img/logos/mohcc.webp';
import nappdLogo from '../media/img/logos/nappd.jpeg';
import zimnamhLogo from '../media/img/logos/zimnamh.jpeg';
import EnrollButton from "./EnrollButton";
import { connect } from "react-redux";
import womanPicture from '../media/img/woman.jpg';
import Statistics from "./Statistics";


function TickedPoint(props) {

   return <div 
      className="valign"
   >
      <CheckCircleIcon
         style={{
            color: 'green',
            marginRight: '16pt',
            fontSize: '56pt'
         }}
      />

      <span
         style={{
            fontSize: '16pt'
         }}
      >
         {props.children}
      </span>
   </div>
}


function PartnerThumbnail(props) {

   return <div
      style={{
         width: '64pt',
         aspectRatio: 1,
         // borderRadius: '50%',
         // border: '2px solid green',
         overflow: 'hidden',
      }}
   >
      <LazyloadImage src={props.imgSrc} aspectRatio={1} objectFit="contain" />
   </div>
}

function AboutUs(props) {


   let aboutTextFontSize;

   switch (props.device.size) {
      case 'xs':
      case 'sm':
         aboutTextFontSize = '20px';
         break;
      default:
         aboutTextFontSize = '30px';
   
   }

   return <section id="about">

      <Grid container>

         <Hidden only={[ 'xs', 'sm' ]}>
            <Grid xs={12} md={6}>
               <div className="vh-align fill-container">
                  <div style={{ width: '60%', borderRadius: 20, overflow: 'hidden', transform: 'scaleX(-1)' }}>
                     <LazyloadImage
                        src={womanPicture}
                        objectFit="cover"
                     />
                  </div>
               </div>
            </Grid>
         </Hidden>

         <Grid item xs={12} md={6}>
            <div  style={{ margin: '32pt' }}>
               <h2>ABOUT QUALITY RIGHTS ZIMBABWE</h2>

               <p 
                  style={{
                     '--font-size': aboutTextFontSize, 
                     fontSize: 'var(--font-size)', 
                     lineHeight: 'calc(4/3 * var(--font-size))', 
                  }}
                  className="font-extralight"
               >
                  Quality Rights Zimbabwe aims to improve the quality of care in mental health and related services and to promote the rights of people with psychosocial, intellectual and cognitive disabilities. It is a global initiative adopted in Zimbabwe and is being rolled out with the support of the World Health Organization, CBM Global, Zimbabwe National Association for Mental Health, Pamumvuri and the National Association of Persons with Psychological Disabilities among other partners.
               </p>
            </div>


            <div style={{ margin: '32pt' }}>
               <Grid container>
                  {
                     [ whoLogo, zimnamhLogo, nappdLogo, cbmLogo, pamumvuriLogo, ].map(logo => {
                        return <Grid item xs={6} md={2.4}>
                           <PartnerThumbnail imgSrc={logo} />
                        </Grid>
                     })
                  }
               </Grid>
            </div>
               

         </Grid>

         <Grid item xs={12} md={6} style={{ background: '#FAFAFA' }}>
            <div style={{ margin: '32pt' }}>
               <h2>STRUGGLING WITH MENTAL HEALTH?</h2>
               
               <div>
                  <TickedPoint>Don't give up</TickedPoint>
                  <TickedPoint>You're not alone</TickedPoint>
                  <TickedPoint>You matter</TickedPoint>
               </div>


               <p>
                  Enroll for the WHO Quality Rights e-training on mental health to know your rights
               </p>

               <EnrollButton />

            </div>

               
         </Grid>

         
         <Grid xs={12} md={6} style={{ background: '#f0f0f0', paddingBottom: 20 }}>
            <Statistics />
         </Grid>
         

      </Grid>
      
   </section>
}



function mapStateToProps(state) {
   const { device } = state;
   return { device };
}

export default connect(mapStateToProps)(AboutUs);