import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel } from "@mui/material";
import Component from "@xavisoft/react-component";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import DateRangePicker from "./DateRangePicker";


export default class GeneratePdfReport extends Component {

   state = {
      from: 0,
      to: Date.now(),
      includeProcessed: false,
   }

   toggleInclusion =  async () => {
      const includeProcessed = !this.state.includeProcessed;
      await this.updateState({ includeProcessed });
   }

   generate = async () => {

      try {

         showLoading();

         const { from, to, includeProcessed } = this.state;
         const res = await request.get(`/api/certificates?from=${from}&to=${to}&include_processed=${includeProcessed}`);

         if (res.data.certificates.length === 0)
            return swal('No entries matching that criteria');

         const body = res.data.certificates.map((certificate, i) => {
            const { name, surname, phone, email, wallet } = certificate;
            return [ i + 1, name, surname, phone, email, wallet ];
         });

         const head = [ [ '', 'Name', 'Surname', 'Phone', 'Email', 'Wallet' ] ];

         const doc = new jsPDF();

         const fromReadable = (new Date(from)).toLocaleDateString().replaceAll('/', '-');
         const toReadable = (new Date(to)).toLocaleDateString().replaceAll('/', '-');
         const title = `Quality Rights Payout Details (${fromReadable} — ${toReadable})`;

         doc.setFontSize(17);
         doc.text(10, 20, title);

         const config = { head, body, startY: 30 }

         autoTable(doc, config);

         const filename = `${title}.pdf`;
         doc.save(filename);

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

	}

   onDateRangeChange = ({ from, to }) => {
      return this.updateState({ from, to });
   }

   render() {
      return <Dialog open={this.props.open}>
         <DialogTitle>GENERATE PDF</DialogTitle>

         <DialogContent>

            <div  style={{ margin: '20px 0' }}>
               <FormLabel>DATE RANGE</FormLabel>
            </div>

            <DateRangePicker
               onChange={this.onDateRangeChange}
            />

            <div onClick={this.toggleInclusion} style={{ marginTop: 20 }}>
               <Checkbox checked={this.state.includeProcessed} />
               <FormLabel style={{ cursor: 'pointer' }}>Include processed entries</FormLabel>
            </div>

         </DialogContent>

         <DialogActions>
            <Button onClick={this.generate} variant="contained">
               GENERATE
            </Button>
            <Button onClick={this.props.close}>
               CLOSE
            </Button>
         </DialogActions>
      </Dialog>
   }
}