import Component from "@xavisoft/react-component";
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import swal from 'sweetalert';
import { Button, Grid } from "@mui/material";
import Event from './Event';
import DeleteIcon from '@mui/icons-material/Delete';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/icons-material/Menu';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddEvent from "./AddEvent";



class EventManager extends Component {


   state = {
      dataFetched: false,
      events: [],
      addEventModalOpen: false
   }

   openAddEventModal = () => {
      return this.updateState({ addEventModalOpen: true });
   }

   closeAddEventModal = (newEvent) => {

      const update = { addEventModalOpen: false };

      if (newEvent && Object.keys(newEvent).length < 10)
         update.events = [ ...this.state.events, newEvent ];


      return this.updateState(update);
   }


   deleteEvent = async (e) => {

      const id = parseInt(e.currentTarget.getAttribute('data-event-id'));
      const name = e.currentTarget.getAttribute('data-event-name');

      // confirm
      const confirmed = await swal({
         title: "Are you sure?",
         text: `Are you sure that you want to delete the event '${name}'?`,
         icon: "warning",
         dangerMode: true,
      });

      if (!confirmed)
         return;


      try {
         showLoading();

         await request.delete(`/api/events/${id}`);

         const events = this.state.events.filter(event => event.id !== id);
         await this.updateState({ events });

         swal('Event deleted');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }


   }

   fetchData = async () => {

      try {

         showLoading();

         const res = await request.get('/api/events');
         const events = res.data;
         await this.updateState({ events, dataFetched: true })


      } catch (err) {
         swal(String(err));
         await this.updateState({ dataFetched: false })
      } finally {
         hideLoading();
      }
   }

   componentDidMount() {
      this.fetchData();
   }

   render() {

      let JSX;

      if (this.state.dataFetched) {

         let eventsJSX

         if (this.state.events.length) {
            eventsJSX = <Grid container spacing={3}>
               {
                  this.state.events.map(event => {
                     return <Grid item xs={3}>
                        <Event
                           name={event.name}
                           time={event.time}
                           location={event.location}
                           image={event.image}
                        />

                        <div className="right-align">
                           <Button 
                              data-event-id={event.id}
                              data-event-name={event.name} 
                              onClick={this.deleteEvent}
                              style={{
                                 color: 'red'
                              }}
                           >
                              <DeleteIcon />
                           </Button>
                        </div>
                     </Grid>
                  })
               }
            </Grid>
            
         } else {
            eventsJSX = <p className="grey-text">
               No upcoming events
            </p>
         }

         JSX = <>
            <h3>EVENTS</h3>
            {eventsJSX}

            <AddEvent
               open={this.state.addEventModalOpen}
               close={this.closeAddEventModal}
            />

            <div>
               <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                  icon={<SpeedDialIcon />}
               >
                  <SpeedDialAction
                     key={1}
                     icon={<AddIcon />}
                     tooltipTitle="Add event"
                     onClick={this.openAddEventModal}
                  />

                  <SpeedDialAction
                     key={2}
                     icon={<RefreshIcon />}
                     tooltipTitle="Refresh"
                     onClick={this.fetchData}
                  />

               </SpeedDial>
            </div>
         </>

      } else {
         JSX = <div className="vh-align fill-container">
            <div>
               Something went wrong
               <br/>

               <Button
                  fullWidth
                  onClick={this.fetchData}
               >
                  RETRY
               </Button>
            </div>
         </div>
      }

      return JSX;
   }
}

export default EventManager;