
function setDeviceInfoReducer(state, device) {
   return { ...state, device };
}

function setCurrentRouteReducer(state, payload) {
   const { route:currentRoute } = payload;
   return { ...state, currentRoute };
}

function setOrganizationsReducer(state, organizations) {
   return { ...state, organizations }
}

function addOrganizationReducer(state, organization) {
   const organizations = [ ...state.organizations, organization ];
   return { ...state, organizations }
}

function removeOrganizationReducer(state, id) {
   const organizations = state.organizations.filter(item => item.id !== id);
   return { ...state, organizations }
}


function updateOrganizationReducer(state, payload) {

   const { id, updates } = payload;

   const organizations = state.organizations.map(org => {
      if (id === org.id)
         return { ...org, ...updates };
      return org;
   });

   return { ...state, organizations };
}


function reducer(state, action) {

   const { type, payload } = action;

   switch (type) {

      case 'SET_DEVICE_INFO':
         return setDeviceInfoReducer(state, payload);

      case 'SET_CURRENT_ROUTE':
         return setCurrentRouteReducer(state, payload);

      case 'SET_ORGANIZATIONS':
         return setOrganizationsReducer(state, payload);
      
      case 'ADD_ORGANIZATION':
         return addOrganizationReducer(state, payload);

      case 'REMOVE_ORGANIZATION':
         return removeOrganizationReducer(state, payload);

      case 'UPDATE_ORGANIZATION':
         return updateOrganizationReducer(state, payload);

      default:
         console.info('Invalid action type:', type);
         return state;
   }
}


export default reducer;