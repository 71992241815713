
import { css } from '@emotion/css';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Component from '@xavisoft/react-component';
import LazyloadImage from './LazyloadImage';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LabelIcon from '@mui/icons-material/Label';
import Moment from 'react-moment';


const containerCSS = css({
   border: '1px #ccc solid',
   borderRadius: '5pt',
   fontFamily: 'Arial',
   color: 'grey',

   '& .icon': {
      marginRight: 5,
   }
})


class Event extends Component {

   render() {

      const props = this.props;

      return <div className={containerCSS}>

         <div>
            <LazyloadImage
               src={props.image}
               objectFit='cover'
               aspectRatio={16 / 9}
            />
         </div>

         <div style={{ margin: '12pt' }}>
            <div style={{ fontSize: '12pt', margin: '3pt auto' }} className="valign">
               <EventNoteIcon className='icon' />
               <Moment>{props.time}</Moment>
            </div>

            <div style={{ fontSize: '14pt', margin: '3pt auto', fontWeight: 'bold' }} className="valign">
               <LabelIcon className="icon" />
               {props.name}
            </div>

            <div style={{ fontSize: '12pt', margin: '3pt auto' }} className="valign">
               <LocationOnIcon className="icon" />
               {props.location}
            </div>
         
         </div>
      </div>
      
   }

}


export default Event;