
import swal from "sweetalert";
import { hideLoading, showLoading } from './loading';
import request from './request';
import actions from "./actions";

async function fetchOrganizations(){
   try {

      showLoading();
      const res = await request.get('/api/organizations');
      actions.setOrganizations(res.data);

   } catch (err) {
      swal(String(err));
   } finally {
      hideLoading()
   }
}

export {
   fetchOrganizations
}