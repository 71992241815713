
import store from "./store";

function setDeviceInfo(device) {

   const action = {
      type: 'SET_DEVICE_INFO',
      payload:  device
   }

   store.dispatch(action);

}

function setCurrentRoute(route) {

   const action = {
      type: 'SET_CURRENT_ROUTE',
      payload:  { route }
   }

   store.dispatch(action);

}


function setOrganizations(organizations) {

   const action = {
      type: 'SET_ORGANIZATIONS',
      payload: organizations,
   }

   store.dispatch(action);

}

function removeOrganization(id) {

   const action = {
      type: 'REMOVE_ORGANIZATION',
      payload: id,
   }

   store.dispatch(action);

}

function addOrganization(organization) {

   const action = {
      type: 'ADD_ORGANIZATION',
      payload: organization,
   }

   store.dispatch(action);

}

function updateOrganization(id, updates) {

   const action = {
      type: 'UPDATE_ORGANIZATION',
      payload: { id, updates },
   }

   store.dispatch(action);

}



const actions = {
   addOrganization,
   removeOrganization,
   setCurrentRoute,
   setDeviceInfo,
   setOrganizations,
   updateOrganization,
}


export default actions;