import ZimFlagSvg from '../media/img/zim-flag.svg';


function ZimFlagIcon(props) {
   return <img
      {...props}
      src={ZimFlagSvg}
      alt=""
   />
}


export default ZimFlagIcon;