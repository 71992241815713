
import { Button, Divider, Tab, Tabs } from "@mui/material";
import { css } from '@emotion/css';
import CertificateManager from "../components/CertificateManager";
import EventManager from "../components/EventManager";
import Page from "./Page";
import SchoolIcon from '@mui/icons-material/School';
import SizeConsciousComponent from '../components/SizeConsciousComponent';
import EventIcon from '@mui/icons-material/Event';
import { connect } from 'react-redux';
import { hideLoading, showLoading } from "../loading";
import swal from "sweetalert";
import request from "../request";
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import GeneratePdfReport from "../components/GeneratePdfReport";
import DownloadCertificates from "../components/DownloadCertificates";
import BusinessIcon from '@mui/icons-material/Business';
import OrganizatioManager from "../components/OrganizationManager";


const menuCss = css({
   '& .Mui-selected': {
      fontWeight: 'bolder',
      fontStretch: 'extra-expanded'
   },

   '& *': {
      color: 'white !important',
   },

   '& .MuiTabs-indicator': {
      backgroundColor: 'white'
   },

   '& .MuiButton-root': {
      // color: 'var(--primary) !important'
   },

   position: 'relative',

});



class Dashboard extends Page {

   state = {
      tab: 'certificates',
      pdfGeneratorOpen: false,
      certificateDownloaderOpen: false,
   }

   openPDFGenerator = () => {
      this.updateState({ pdfGeneratorOpen: true });
   }

   closePDFGenerator = () => {
      this.updateState({ pdfGeneratorOpen: false });
   }

   openCertificateDownloader = () => {
      this.updateState({ certificateDownloaderOpen: true });
   }

   closeCertificateDownloader = () => {
      this.updateState({ certificateDownloaderOpen: false });
   }

   onTabChange = (event, tab) => {
      return this.updateState({ tab })
   }

   logout = async () => {
      
      try {

         showLoading();

         await request.delete('/api/login');
         window.App.redirect('/login');
         
      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   _render() {

      if (!this.props.show) {
         return <div 
            style={{
               height: 'calc(var(--window-height) - var(--navbar-height))',
            }}
            className="vh-align"
         >
            <h5>Use a bigger screen to access the dashboard</h5>
         </div>
      }

      let Manager;

      switch (this.state.tab) {
         case 'events':
            Manager = EventManager;
            break;

         case 'organizations':
            Manager = OrganizatioManager;
            break;

         default:
            Manager = CertificateManager;
      }

      return <div
         style={{
            display: 'grid',
            gridTemplateColumns: '1fr 6fr',
            margin: 0,
            padding: 0,
         }}
      >

         <div>

            <div 
               style={{
                  height: 'calc(var(--window-height) - var(--navbar-height))',
                  padding: 20,
                  boxSizing: 'border-box'
               }}
               className={`primary-bg ${menuCss}`}
            >
               <div style={{ fontSize: 25, margin: 20 }} className="center-align">MENU</div>

               <Tabs value={this.state.tab} onChange={this.onTabChange} orientation="vertical">

                  <Tab 
                     label="CERTIFICATES" 
                     value="certificates"
                     icon={<SchoolIcon />}
                     iconPosition="start"
                  />
                  <Tab 
                     label="EVENTS" 
                     value="events"
                     icon={<EventIcon />}
                     iconPosition="start"
                  />
                  <Tab 
                     label="ORGANIZATIONS" 
                     value="organizations"
                     icon={<BusinessIcon />}
                     iconPosition="start"
                  />
               </Tabs>

               <Divider />

               <div className="center-align">
                  <Button
                     variant="text"
                     size="large"
                     style={{
                        marginTop: 20,
                     }}
                     onClick={this.logout}
                     startIcon={<ArrowBackIcon />}
                  >
                     LOGOUT
                  </Button>
               </div>

               <div style={{ position: 'absolute', bottom: 20, right: 20, left: 20 }}>
                  <Button
                     variant="text"
                     size="large"
                     style={{
                        marginTop: 20,
                     }}
                     onClick={this.openPDFGenerator}
                     startIcon={<PdfIcon />}
                  >
                     GENERATE PDF
                  </Button>

                  <Button
                     variant="text"
                     size="large"
                     style={{
                        marginTop: 20,
                     }}
                     onClick={this.openCertificateDownloader}
                     startIcon={<DownloadIcon />}
                  >
                     CERTIFICATES
                  </Button>

                  <GeneratePdfReport open={this.state.pdfGeneratorOpen} close={this.closePDFGenerator} />
                  <DownloadCertificates open={this.state.certificateDownloaderOpen} close={this.closeCertificateDownloader} />
               </div>
            </div>

         </div>

         <div 
            style={{ 
               '--dashboard-display-height': 'calc(var(--window-height) - var(--navbar-height))',
               boxSizing: 'border-box',
               '--dashboard-display-padding': '30px',
               padding: 'var(--dashboard-display-padding)',
               height: 'var(--dashboard-display-height)',
            }}>
            
            <DashboardPageTitle
               id="dashboard-page-title"
               variablePrefix="--dashboard-page-title"
            />

            <div 
               style={{ 
                  '--dashboard-content-height': 'calc(var(--dashboard-display-height) - var(--dashboard-page-title-height) - 2 * var(--dashboard-display-padding))', 
                  overflowY: 'auto',
                  height: 'var(--dashboard-content-height)'
               }}
            >
               <Manager />
            </div>
               
         </div>
      </div>
   }
}

class DashboardPageTitle extends SizeConsciousComponent {

   render() {
      return <div id={this.props.id} style={{ margin: 0 }}>
         <div 
            style={{ fontSize: 30, 
               fontWeight: 'bold', 
               paddingBottom: 20 
            }} 
            className="primary-text"
         >DASHBOARD</div>
         <Divider />
      </div>
   }
}


function mapStateToProps(state) {

   let show;

   switch (state.device.size) {
      case 'xs':
      case 'sm':
         show = false;
         break;
      default:
         show = true;
   }

   return { show }
}

export default connect(mapStateToProps)(Dashboard);