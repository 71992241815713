import swal from "sweetalert";


function delay(millis) {
   return new Promise((resolve) => {
      setTimeout(resolve, millis)
   });
}

function requestConfirmation({
   question,
   confirmButtonCaption='Yes',
   cancelButtonCaption="Cancel",
}) {
   return swal({
      text: question,
      buttons: [
         {
            text: confirmButtonCaption,
            value: true,
            className: "bg-red-600 text-white",
            visible: true,
         },
         {
            text: cancelButtonCaption,
            value: false,
            visible: true,
            className: "bg-white primary-text"
         }
      ],
      closeOnClickOutside: false,
      closeOnEsc: false,
   });
}

function objectToQueryString(obj={}) {
   return Object
      .keys(obj)
      .filter(key => {
         return !!obj[key]
      })
      .map(key => {
         return `${key}=${obj[key]}`
      }).join('&');

}

function organizationIdToImageUrl(id) {
   return `/img/organizations/${id}.png`;
}

export {
   delay,
   objectToQueryString,
   organizationIdToImageUrl,
   requestConfirmation,
}