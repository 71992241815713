import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import CropImage from './CropImage';
import { errorToast } from "../toast";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import actions from "../actions";
import swal from "sweetalert";
import { delay, organizationIdToImageUrl } from "../utils";
import LazyloadImage from "./LazyloadImage";
import EditIcon from '@mui/icons-material/Edit';


// component
export default class OrganizationEditor extends Component {

   state = {
      editPicture: false,
   }

   addOrganization = async () => {

      const txtName = document.getElementById('txt-name');
      const txtDetails = document.getElementById('txt-details');
      const txtWebsite = document.getElementById('txt-website');
      const txtImage = document.getElementById('txt-image');

      const name = txtName.value;
      const details = txtDetails.value;
      const website = txtWebsite.value;
      const image = txtImage.value;

      if (!name) {
         errorToast('Name is required');
         return txtName.focus();
      }

      if (!details) {
         errorToast('Details are required');
         return txtDetails.focus();
      }

      if (!website) {
         errorToast('Website is required');
         return txtWebsite.focus();
      }

      if (!image) {
         errorToast('Image is required');
         return txtImage.focus();
      }

      try {

         showLoading();
         const data = { name, image, website, details };
         const res = await request.post('/api/organizations', data);

         data.id = res.data.id;
         actions.addOrganization(data);

         this.props.close();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   editOrganization = async () => {

      // extract
      const txtName = document.getElementById('txt-name');
      const txtDetails = document.getElementById('txt-details');
      const txtWebsite = document.getElementById('txt-website');

      const name = txtName.value;
      const details = txtDetails.value;
      const website = txtWebsite.value;

      if (!name) {
         errorToast('Name is required');
         return txtName.focus();
      }

      if (!details) {
         errorToast('Details are required');
         return txtDetails.focus();
      }

      if (!website) {
         errorToast('Website is required');
         return txtWebsite.focus();
      }

      const data = { set: {} }

      if (this.state.editPicture) {

         const txtImage = document.getElementById('txt-image');
         const image = txtImage.value;

         if (!image) {
            errorToast('Image is required');
            return txtImage.focus();
         }

         data.set.image = image
      }

      // build request payload
      const orgData = this.props.organization;

      if (name !== orgData.name)
         data.set.name = name;

      if (details !== orgData.details)
         data.set.details = details;

      if (website !== orgData.website)
         data.set.website = website;

      
      if (Object.keys(data.set).length === 0)
         return errorToast("No changes made");

      try {

         showLoading();

         const { id } = orgData;
         await request.patch(`/api/organizations/${id}`, data);

         actions.updateOrganization(id, data.set);
         this.props.close();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   save = () => {
      if (this.props.mode === 'add')
         this.addOrganization();
      else
         this.editOrganization();
   }

   async componentDidMount() {

      if (this.props.mode === 'edit') {
         // fill in textboxes
         await delay(100);

         Object
            .keys(this.props.organization)
            .forEach(key => {
               try {
                  document.getElementById(`txt-${key}`).value = this.props.organization[key];
               } catch {}
            });
      }
   }

   render() {


      let imgInput;
      const cropImage = <CropImage
         aspectRatio={1}
         id="txt-image"
      />

      if (this.props.mode === 'add') {
         imgInput = cropImage;
      } else {
         if (this.state.editPicture) {
            imgInput = cropImage;
         } else {

            const { id } = this.props.organization;

            imgInput = <div>
               <div>
                  <LazyloadImage
                     src={organizationIdToImageUrl(id)}
                  />
               </div>
            
               <Button 
                  startIcon={<EditIcon />}
                  onClick={() => this.updateState({ editPicture: true })}
               >
                  EDIT
               </Button>
            </div>

         }
      }

      return <Dialog open onClose={this.props.close}>

         <DialogTitle>
            ADD ORGANIZATION
         </DialogTitle>

         <DialogContent dividers>
            <div className="max-w-[400px] [&>*]:my-3">
               <TextField
                  fullWidth
                  id="txt-name"
                  size="small"
                  placeholder="Name"
                  variant="standard"
               />

               <TextField
                  fullWidth
                  id="txt-details"
                  size="small"
                  placeholder="Details"
                  variant="standard"
                  multiline
               />

               <TextField
                  fullWidth
                  id="txt-website"
                  size="small"
                  placeholder="Website"
                  variant="standard"
                  multiline
               />

               {imgInput}

               <Button 
                  variant="contained" 
                  size="small" 
                  fullWidth
                  onClick={this.save}
               >
                  SAVE
               </Button>
            </div>
         </DialogContent>
      </Dialog>
   }
}