
import { Button, CircularProgress, Grid } from '@mui/material';
import Component from '@xavisoft/react-component';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import request from '../request';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PieChart from './PieChart';
import StatsModal from './StatsModal';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);


class Statistics extends Component {

   state = {
      dataFetched: false,
      statsModalOpen: false,
   }

   delayBeforRetry = 5000;

   closeStatsModal = () => {
      return this.updateState({ statsModalOpen: false });
   }

   fetchData = async () => {

      try {

         const res = await request.get('/api/certificates/stats');
         await this.updateState({ dataFetched: true, ...res.data });

      } catch (err) {
         console.error(err);
         setTimeout(this.fetchData, this.delayBeforRetry);
         this.delayBeforRetry *= 2;
      }
   }

   componentDidMount() {
      this.fetchData();
   }

   render () {

      let JSX;

      if (this.state.dataFetched) {

         let statsModal;

         if (this.state.statsModalOpen) {
            statsModal = <StatsModal
               close={this.closeStatsModal}
            />
         }

         JSX = <div className='center-align'>

            {statsModal}

            <h2>STATISTICS</h2>

            <div>
               <div
                  style={{
                     fontSize: 40,
                     fontWeight: 'bold'
                  }}
                  className="primary-text"
               >
                  {this.state.total}
               </div>

               <div
                  style={{
                     fontSize: 10,
                     fontWeight: 'bold'
                  }}
                  className="grey-text"
               >
                  TRAINED PEOPLE
               </div>
            </div>

            <Grid container spacing={2}>
               <Grid item xs={12} md={6}>
                  <div className='="fill-container vh-align'>
                     <div style={{ width: '80%', maxWidth: 400, aspectRatio: 1 }}>
                        <PieChart
                           label="Trained people by profession"
                           items={{
                              "Mental Health": this.state.professions.mental,
                              "Medical": this.state.professions.medical,
                              "Other fields": this.state.professions.other,
                           }}
                        />
                     </div>
                  </div>
               </Grid>
               <Grid item xs={12} md={6}>
                  <div className='="fill-container vh-align'>
                     <div style={{ width: '80%', maxWidth: 400, aspectRatio: 1 }}>
                        <PieChart
                           label="Trained people by province"
                           items={this.state.provinces}
                        />
                     </div>
                  </div>
               </Grid>
            </Grid>
            
            <div className='text-right pr-[40px] mt-[40px]'>
               <Button 
                  variant='contained' 
                  size="large" 
                  className='rounded-full'
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => this.updateState({ statsModalOpen: true })}
               >
                  MORE
               </Button>
            </div>

         </div>
      } else {
         JSX = <div className='fill-container vh-align'>
            <CircularProgress variant="indeterminate" />
         </div>
      }

      return JSX;
   }
}


export default Statistics;