
import LazyloadImage from "./LazyloadImage";


export default function Partner(props) {

   const { website } = props;

   let href;

   if (website && website.indexOf("http") === 0)
      href = website;
   else
      href = `//${website}`;

   return <div className="font-[Arial] grid grid-cols-1 md:grid-cols-[200px,auto] my-8 mx-4">

      <div className="h-align">
         <div className="w-[50%]">
            <LazyloadImage 
               aspectRatio="1"
               src={props.logo}
               objectFit="contain" 
            />
         </div>
      </div>

      <div>
         <div className="text-xl text-gray-600 font-bold">
            {props.name}
         </div>
         <p className="text-gray-600">
            {props.description}
         </p>

         <a href={href}>
            {props.website}
         </a>
      </div>

   </div>

}