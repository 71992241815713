import Component from "@xavisoft/react-component";
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import swal from 'sweetalert';
import { Button, Checkbox, FormLabel, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CertificatePreview from "./CertificatePreview";
import { css } from "@emotion/css";
import BulkProcessor from "./BulkProcessor";
import DateRangePicker from "./DateRangePicker";
import { objectToQueryString } from "../utils";


const tableCss = css({
   '& thead, th': { 
      position: 'sticky', 
      top: 0, 
      zIndex: 1,
   },
   '& thead': {
      background: 'white'
   }
});


const LIMIT = 50;

class CertificateManager extends Component {


   state = {
      dataFetched: false,
      certificates: [],
      certificatePreview: null,
      page: 1,
      count: 0,
      pages: 0,
      from: 0,
      to: Date.now(),
      includeProcessed: false,
      bulkProcessorOpen: false
   }

   openBulkProcessor = () => {
      return this.updateState({ bulkProcessorOpen: true })
   }

   closeBulkProcessor = () => {
      return this.updateState({ bulkProcessorOpen: false })
   }

   toggleInclusion =  async () => {
      const includeProcessed = !this.state.includeProcessed;
      await this.updateState({ includeProcessed, page: 1 });
      this.fetchData();
   }

   previewCertificate = (e) => {
      const certificatePreview = e.currentTarget.getAttribute('data-certificate-url');
      return this.updateState({ certificatePreview });
   }

   closeCertificatePreview = () => {
      return this.updateState({ certificatePreview: null })
   }


   togglePaymentProcessed = async (event) => {

      const target = event.currentTarget;
      const payment_processed = !JSON.parse(target.getAttribute('data-payment-processed'));
      const id = parseInt(target.getAttribute('data-certificate-id'));

      try {

         showLoading();
         await request.post(`/api/certificates/${id}/payment-processed`, { payment_processed });

         let certificates;

         if (payment_processed && !this.state.includeProcessed) {
            // exlude, it's no longer supposed to be displayed
            certificates = this.state.certificates.filter(certificate  => certificate.id !== id);
         } else {
            // update payment_processed value
            certificates = this.state.certificates.map(certificate => {
               if (certificate.id === id) {
                  certificate.payment_processed = payment_processed;
               }

               return certificate;
            });
         }

         await this.updateState({ certificates });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }


   fetchData = async (page=this.state.page) => {

      try {

         showLoading();

         if (typeof page !== 'number')
            page = 1;

         const offset = (page - 1) * LIMIT;

         const {
            includeProcessed: include_processed,
            from,
            to,
         } = this.state;

         const query = objectToQueryString({
            offset,
            limit: LIMIT,
            include_processed,
            from,
            to,
         })

         const res = await request.get(`/api/certificates?${query}`);
         const { count, certificates } = res.data;

         const pages = parseInt((count - 1) / LIMIT) + 1;

         await this.updateState({ 
            certificates, 
            count, 
            dataFetched: true,
            pages,
            page,
         });


      } catch (err) {
         swal(String(err));
         await this.updateState({ dataFetched: false })
      } finally {
         hideLoading();
      }
   }

   onDateRangeChange = async ({ from, to }) => {
      console.clear();
      console.log({ 
         _from: new Date(from).toDateString(), 
         _to: new Date(to).toDateString(),
         from,
         to,
      });

      await this.updateState({ from, to });
      this.fetchData();
   }

   onPageChange = (_, page) => {
      this.fetchData(page);
   }

   componentDidMount() {
      this.fetchData();
   }

   render() {

      let JSX;

      if (this.state.dataFetched) {

         let certificatesJSX
         let noCertificatesJSX;

         if (this.state.certificates.length) {
            certificatesJSX = this.state.certificates.map(certificate => {

               let paymentProcessedJSX;
               let togglePaymentProcessedIcon;
               let tooltipText;

               if (certificate.payment_processed) {
                  paymentProcessedJSX = <CheckCircleIcon style={{ color: 'green' }} />;
                  togglePaymentProcessedIcon = <CloseIcon style={{ color: 'red' }} />;
                  tooltipText = 'Mark as not processed'
               } else {
                  paymentProcessedJSX = <CancelIcon style={{ color: 'red' }}/>;
                  togglePaymentProcessedIcon = <CheckIcon style={{ color: 'green' }}/>;
                  tooltipText = 'Mark as processed'
               }

               return <TableRow>
                     <TableCell>{certificate.name} {certificate.surname}</TableCell>
                     <TableCell>{certificate.wallet}</TableCell>
                     <TableCell>{certificate.email}</TableCell>
                     <TableCell>{certificate.phone}</TableCell>

                     <TableCell>
                        <div className="center-align">
                           {paymentProcessedJSX}
                        </div>
                     </TableCell>

                     <TableCell>

                        <Button onClick={this.previewCertificate} data-certificate-url={certificate.file}>
                           <VisibilityIcon />
                        </Button>

                        <Button 
                           onClick={this.togglePaymentProcessed}
                           data-payment-processed={certificate.payment_processed}
                           data-certificate-id={certificate.id}
                           title={tooltipText}
                        >
                           {togglePaymentProcessedIcon} 
                        </Button>  
                     </TableCell>
               </TableRow>
            });
               
            
         } else {
            noCertificatesJSX = <p className="grey-text">
               No certificates
            </p>
         }

         JSX = <div
            style={{
               '--div-actions-height': '50px',
            }}
         >

            <div 
               style={{ 
                  height: 'calc(var(--dashboard-content-height) - var(--div-actions-height))',
                  overflowY: 'auto'
               }}
            >
               <h3>CERTIFICATES</h3>

               <Table className={tableCss}>
                  <TableHead>
                     <TableRow>
                        <TableCell><b>NAME</b></TableCell>
                        <TableCell><b>WALLET</b></TableCell>
                        <TableCell><b>EMAIL</b></TableCell>
                        <TableCell><b>PHONE</b></TableCell>

                        <TableCell>
                           <div className="center-align">
                              <b>PROCESSED</b>
                           </div>
                        </TableCell>

                        <TableCell></TableCell>
                     </TableRow>
                  </TableHead>

                  <TableBody>
                     {certificatesJSX}
                  </TableBody>
               </Table>
               {noCertificatesJSX}
            </div>

            <div className="v-align" style={{ height: 'var(--div-actions-height)' }}>
               
               <Pagination
                  count={this.state.pages}
                  page={this.state.page}
                  onChange={this.onPageChange}
               />

               <span onClick={this.toggleInclusion}>
                  <Checkbox checked={this.state.includeProcessed} />
                  <FormLabel style={{ cursor: 'pointer' }}>Include processed</FormLabel>
               </span>

               <span>
                  <Button 
                     variant="contained" 
                     size="small" 
                     startIcon={<CheckIcon />} 
                     style={{ margin: 'auto 30px'}} 
                     color="secondary"
                     onClick={this.openBulkProcessor}
                  >
                     BULK STATUS UPDATE
                  </Button>

                  <BulkProcessor
                     open={this.state.bulkProcessorOpen}
                     close={this.closeBulkProcessor}
                     reloadCertificates={this.fetchData}
                  />
               </span>

               <div
                  style={{
                     maxWidth: 300,
                  }}
               >
                  <DateRangePicker onChange={this.onDateRangeChange} />
               </div>

               <span style={{ paddingLeft: 20 }}>
                  <b>{this.state.count}</b> certificates
               </span>

               <Button
                  onClick={this.fetchData}
               >
                  <RefreshIcon />
               </Button>

            </div>

            <CertificatePreview
               open={!!this.state.certificatePreview}
               close={this.closeCertificatePreview}
               file={this.state.certificatePreview}
            />
         </div>

      } else {
         JSX = <div className="vh-align fill-container">
            <div>
               Something went wrong
               <br/>

               <Button
                  fullWidth
                  onClick={this.fetchData}
               >
                  RETRY
               </Button>
            </div>
         </div>
      }

      return JSX;
   }
}


export default CertificateManager;