import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import DateRangePicker from './DateRangePicker';
import { PROVINCES } from "../constants";
import PieChart from "./PieChart";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { objectToQueryString } from "../utils";
import { connect } from "react-redux";
import { fetchOrganizations } from "../organizations";


// constants
const ALL = 'i_represent_all_choices_delete_me_if_i_am_part_of_the_query_string';
const ALL_MENU_ITEM_JSX = <MenuItem value={ALL}>All</MenuItem>

// component
class UnconnectedStatsModal extends Component {

   state = {
      from: 0,
      to: Date.now(),
      coordinator: ALL,
      province: ALL,
   }

   fetchData = async (filterUpdates = {}) => {

      try {

         showLoading();

         const args = { ...this.state, ...filterUpdates};

         for (let key in args) {
            if (args[key] === ALL)
               delete args[key];
         }

         const query = objectToQueryString(args);
         const { data } = await request.get(`/api/certificates/stats?${query}`);
         await this.updateState({ data, ...filterUpdates });

         console.log(data);

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   fetchOrganizationsIfNotFetched = () => {
      if (!this.props.organizations)
         fetchOrganizations();
   }

   componentDidMount() {
      this.fetchOrganizationsIfNotFetched();
   }

   render() {

      const { organizations } = this.props;

      let coordinatorList;

      if (organizations) {
         coordinatorList = organizations.map(org => {
            return <MenuItem value={org.name} key={org.name}>
               {org.name}
            </MenuItem>
         })
      }

      let statistics;

      if (this.state.data) {

         let byProfession, byProvince, byCoordinator;

         const { professions, provinces, coordinators } = this.state.data;

         if (professions) {
            byProfession = <PieChart
               label="By Profession"
               items={professions}
            />
         }

         if (provinces) {
            byProvince = <PieChart
               label="By Province"
               items={provinces}
            />
         }

         if (coordinators) {
            byCoordinator = <PieChart
               label="By Coordinator"
               items={coordinators}
            />
         }

         const total = <div className="text-center text-6xl font-bold text-[var(--primary)] my-2">
            {this.state.data.total || 0}
            <div className="text-gray-500 text-xs">
               TRAINED PEOPLE
            </div>
         </div>

         statistics = <div>
            {total}
            <div className="grid grid-cols-1 md:grid-cols-2 md:px-[15%] gap-6">
               {byProfession}
               {byProvince}
               {byCoordinator}
            </div>
         </div>
      } else {
         statistics = <div className="h-[300px] vh-align">
            <div>
               <p className="text-gray-600">
                  Data not loaded
               </p>

               <Button color="secondary" size="small" onClick={this.fetchData} variant="contained">
                  RETRY
               </Button>
            </div>
         </div>
      }


      return <Dialog open onClose={this.props.close} fullScreen>

         <DialogTitle>
            <div>

               <DateRangePicker
                  onChange={range => {
                     const { from, to } = range;
                     this.fetchData({ from, to });
                  }}
               />

               <div className="grid grid-cols-2 gap-2 mt-4">
                  <TextField
                     select
                     variant="outlined"
                     label="Coordinator"
                     size="small"
                     onFocus={this.fetchOrganizationsIfNotFetched}
                     onChange={
                        e => {
                           const coordinator = e.target.value;
                           this.fetchData({ coordinator });
                        }
                     }
                     value={this.state.coordinator}
                  >
                     {ALL_MENU_ITEM_JSX}
                     {coordinatorList}
                  </TextField>

                  <TextField
                     select
                     variant="outlined"
                     label="Province"
                     size="small"
                     onChange={
                        e => {
                           const province = e.target.value;
                           this.fetchData({ province });
                        }
                     }
                     value={this.state.province}
                  >
                     {ALL_MENU_ITEM_JSX}
                     {
                        PROVINCES.map(province => {
                           return <MenuItem value={province} key={province}>
                              {province}
                           </MenuItem>
                        })
                     }
                  </TextField>
               </div>
            </div>
         </DialogTitle>

         <DialogContent>
            <div>
               {statistics}
            </div>
         </DialogContent>

         <DialogActions>

            <Button onClick={this.props.close}>
               CLOSE
            </Button>
         </DialogActions>
      </Dialog>
   }
}

const mapStateToProps = state => {
   const { organizations } = state;
   return { organizations };
}

const StatsModal = connect(mapStateToProps)(UnconnectedStatsModal);
export default StatsModal;