import { Button } from "@mui/material";

function EnrollButton(props) {

   const fontSize = props.fontSize || '16pt'

   return <a href="https://www.who.int/teams/mental-health-and-substance-use/policy-law-rights/qr-e-training" style={{ textDecoration: 'none' }}>
      <Button 
         variant="contained"
         style={{
            '--font-size': fontSize,
            fontSize: 'var(--font-size)',
            paddingLeft: 'calc(3.5 * var(--font-size))',
            paddingRight: 'calc(3.5 * var(--font-size))',
            height: 'calc(3.5 * var(--font-size))',
            borderRadius: 'calc(2 * var(--font-size))',
            textTransform: 'none',
            fontFamily: "'Unbounded', cursive"
         }}
         className={props.className}
      >
         Enroll
      </Button>
   </a>
}

export default EnrollButton;