
import stressedMan from '../media/img/stressed-man.jpeg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { connect } from "react-redux";
import EnrollButton from "./EnrollButton";
import { Button, Link } from '@mui/material';
import WhatsThisAbout from './WhatsThisAbout';
import Component from '@xavisoft/react-component';
import { HashLink } from 'react-router-hash-link';



class Hero extends Component {

   state = {
      whatsThisAboutShowing: false,
   }

   showWhatsThisAbout = () => {
      return this.updateState({ whatsThisAboutShowing: true });
   }

   hideWhatsThisAbout = () => {
      return this.updateState({ whatsThisAboutShowing: false });
   }
   
   render() {

      const { device } = this.props;

      let attentionTextFontSize, supportingTextFontSize;

      switch (device.size) {
         case 'xs':
         case 'sm':
            attentionTextFontSize = '24pt';
            supportingTextFontSize = '18px';
            break;

         default:
            attentionTextFontSize = '32pt';
            supportingTextFontSize = '20pt';
      }
      
      return <section>
         <div 
            style={{
               height: 'calc(var(--window-height) - var(--navbar-height))',
               background: `url(${stressedMan})`,
               backgroundSize: 'cover'
            }}
         >
            <div 
               className="vh-align fill-container"
               style={{
                  background: 'rgba(0, 0, 0, 0.8)',
                  position: 'relative',
               }}
            >
               <div style={{
                  maxWidth: '400pt',
                  padding: '24pt',
                  color: 'white'
               }}>
                  <h1
                     style={{
                        fontSize: attentionTextFontSize,
                     }}
                  >MENTAL.<br/>HEALTH.<br/>MATTERS.</h1>

                  <p
                     style={{
                        fontSize: supportingTextFontSize
                     }}
                  >
                     Earn <big style={{ color: 'var(--accent-2)' }}>CPD points</big> by completing the <big style={{ color: 'var(--accent-2)' }}>Quality Rights</big> e-training.
                     <br />

                     <Link
                        style={{
                           textUnderlineOffset: 5,
                           fontSize: 20,
                           fontFamily: 'arial',
                           cursor: 'pointer'
                        }}
                        onClick={this.showWhatsThisAbout}
                     >Learn more</Link>
                  </p>

                  <EnrollButton fontSize='12pt' className="pulse" />

                  {/* <Button size='large' onClick={this.showWhatsThisAbout}>
                     <HelpOutlinedIcon style={{ fontSize: 60, color: 'var(--accent-2)' }} />
                  </Button> */}

                  <WhatsThisAbout open={this.state.whatsThisAboutShowing} close={this.hideWhatsThisAbout} />

               </div>

               <div 
                  style={{
                     position: 'absolute',
                     bottom: 0,
                     right: 0,
                     left: 0,
                     textAlign: 'center'
                  }}
               >
                  <Button
                     component={HashLink}
                     to={'/#about'}
                  >
                     <KeyboardArrowDownIcon 
                        style={{ 
                           color: 'white', 
                           fontSize: '32pt' 
                        }}
                     />
                  </Button>
               </div>
            </div>
         </div>
      </section>
   }
}

function stateToProps(state) {
   const { device } = state;
   return { device };
}

export default connect(stateToProps)(Hero);