
import { css } from "@emotion/css";
import { Button } from "@mui/material";
import Component from "@xavisoft/react-component";
import { HashLink } from "react-router-hash-link";
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const sideNavCss = css({
   color: 'white',
   position: 'fixed',
   top: 0,
   left: 0,
   right: 0,
   bottom: 0,

   '& a': {
      color: 'white',
      // textUnderlineOffset: '3px',
      textDecoration: 'none',
      fontSize: '16pt',
      margin: '12pt auto',
      textTransform: 'uppercase',
   }
})

class MobileNav extends Component {

   render() {

      const sideNavStyle = {
         display: this.props.open ? 'flex' : 'none',
      };

      const links = this.props.links || []

      return <div style={sideNavStyle} className={`${sideNavCss} valign primary-bg`}>
         <div className="center-align" style={{ width: '100%'}}>
            {
               links.map(link => {
                  return <HashLink 
                     to={link.to}
                     className="vh-align"
                     onClick={() => {
                        this.props.close();

                        if (typeof link.onClick === 'function')
                           link.onClick();
                           
                     }}
                  >
                     <ChevronRightIcon />
                     {link.caption}
                  </HashLink>
               })
            }
         </div>

         <Button
            style={{
               position: 'absolute',
               top: '24pt',
               right: '24pt',
               color: 'white',
            }}
            onClick={this.props.close}
         >
            <CloseIcon />
         </Button>
      </div>
   }
}


export default MobileNav;