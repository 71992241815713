import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel } from "@mui/material";
import { getAuthToken } from "@xavisoft/auth/frontend/utils";
import { ACCESS_TOKEN_HEADER_NAME } from '@xavisoft/auth/constants';
import Component from "@xavisoft/react-component";
import DateRangePicker from "./DateRangePicker";


export default class DownloadCertificates extends Component {

   state = {
      from: 0,
      to: Date.now()
   }

   download = async () => {

      const { from, to } = this.state;

      const fromReadable = (new Date(from)).toLocaleDateString().replaceAll('/', '-');
      const toReadable = (new Date(to)).toLocaleDateString().replaceAll('/', '-');
      const filename = `Quality Rights Certificates (${fromReadable} — ${toReadable}).zip`;

      const authToken = getAuthToken();
      let url = `/api/certificates/documents?from=${from}&to=${to}&filename=${filename}&${ACCESS_TOKEN_HEADER_NAME}=${authToken}`;

      if (process.env.NODE_ENV !== 'production')
         url = `http://localhost:8080${url}`;

      window.open(url, '_blank');
      
	}

   onDateRangeChange = ({ from, to }) => {
      return this.updateState({ from, to });
   }

   render() {
      return <Dialog open={this.props.open}>
         <DialogTitle>DOWNLOAD CERTIFICATES</DialogTitle>

         <DialogContent>

            <div  style={{ margin: '20px 0' }}>
               <FormLabel>DATE RANGE</FormLabel>
            </div>

            <DateRangePicker
               onChange={this.onDateRangeChange}
            />
         </DialogContent>

         <DialogActions>
            <Button onClick={this.download} variant="contained">
               DOWNLOAD
            </Button>
            <Button onClick={this.props.close}>
               CLOSE
            </Button>
         </DialogActions>
      </Dialog>
   }
}