import Component from "@xavisoft/react-component";



class SizeConsciousComponent extends Component {

   SizeConsciousComponent_setDimensions = (arg) => {

      const elem = document.getElementById(this.props.id);

      if (!elem)
         return;
      
      if (this.props.variablePrefix) {
         const width = elem.offsetWidth + 'px';
         const height = elem.offsetHeight+ 'px'
         document.documentElement.style.setProperty(`${this.props.variablePrefix}-width`, width);;
         document.documentElement.style.setProperty(`${this.props.variablePrefix}-height`, height);
      }

   }

   componentDidMount() {

      const resizeObserver = new window.ResizeObserver(this.SizeConsciousComponent_setDimensions);
      const elem = document.getElementById(this.props.id);

      if (!elem)
         return;

      resizeObserver.observe(elem);
      this.resizeObserver = resizeObserver;

      this.SizeConsciousComponent_setDimensions();

   }
}

export default SizeConsciousComponent;