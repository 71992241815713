import { MetaTags } from "react-meta-tags";
import AboutUs from "../components/AboutUs";
import Hero from "../components/Hero";
import Events from "../components/Events";
import Page from "./Page";


class Home extends Page {

   _render() {
      return <>

         <MetaTags>
            <title>Home | Quality Rights Zimbabwe</title>
            <meta name="description" content="Quality Rights Zimbabwe aims to improve the quality of care in mental health and related services and to promote the rights of people with psychosocial, intellectual and cognitive disabilities." />
         </MetaTags>

         <Hero />
         <AboutUs />
         <Events />
         
      </>
   }
}

export default Home;