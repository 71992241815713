
import Page from './Page';
import { css } from '@emotion/css';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { errorToast } from '../toast';
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import swal from 'sweetalert';

const divloginCss = css({
   maxWidth: 400,
   border: '1px solid #ddd',
   padding: 20,

   '& > *': {
      margin: '10px auto !important',
   }
})


class Login extends Page {

   login = async () => {

      const txtUsername = document.getElementById('txt-username');
      const txtPassword = document.getElementById('txt-password');

      const username = txtUsername.value;
      const password = txtPassword.value;

      if (!username) {
         errorToast('Provide username');
         return txtUsername.focus();
      }

      if (!password) {
         errorToast('Provide password');
         return txtPassword.focus();
      }

      const data = { username, password };

      try {

         showLoading();

         await request.post('/api/login', data);

         window.App.redirect('/dashboard');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
      
   }

   _render() {

      return <div className="vh-align" 
         style={{
            height: 'calc(var(--window-height) - var(--navbar-height))',
         }}
      >
         <div className={divloginCss}>

            <h4>LOGIN</h4>

            <TextField
               fullWidth
               label="Username"
               id='txt-username'
               size='small'
               variant='standard'
            />
            
            <TextField
               fullWidth
               label="Password"
               id='txt-password'
               size='small'
               variant='standard'
               type="password"
            />

            <Button
               fullWidth
               size="large"
               variant='contained'
               onClick={this.login}
               className="primary-bg"
            >LOGIN</Button>

         </div>
      </div>
   }
}

export default Login;