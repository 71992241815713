
import Partner from '../components/Partner';
import Page from './Page';
import pamumvuriLogo from '../media/img/logos/pamumvuri.png';
import cbmLogo from '../media/img/logos/cbm.jpeg';
// import mohcccLogo from '../media/img/logos/mohcc.webp';
import nappdLogo from '../media/img/logos/nappd.jpeg';
import whoLogo from '../media/img/logos/who.png';
import zimnamhLogo from '../media/img/logos/zimnamh.jpeg';
import { MetaTags } from 'react-meta-tags';
import { connect } from 'react-redux';
import { organizationIdToImageUrl } from '../utils';
import { fetchOrganizations } from '../organizations';
import Button from '@mui/material/Button';

// constants
const partners = [
   // {
   //    name: 'Ministry Of Health and Child Care',
   //    logo: mohcccLogo,
   //    description: 'The Ministry Of Health and Child Care is responsible for health matters in Zimbabwe',
   //    website: 'mohcc.gov.zw',
   // },
   {
      name: 'World Health Organization',
      logo: whoLogo,
      description: 'The World Heath Organization (WHO) is responsible for global health matters, shaping health research agenda, setting global health standards and monitoring and assessing health trends',
      website: 'who.int'
   },
   {
      name: 'Zimbabwe National Association for Mental Health',
      logo: zimnamhLogo,
      description: 'Zimbabwe National Association for Mental Health (ZIMNAMH) is a registered welfare organization (W.O39/81) whose primary goal is to promote the recognition of the rights of people with mental disabilities in a society where mental health is valued and managed with parity. It started operating in 1981.',
      website: 'zimnamh.org.zw'
   },
   {
      name: 'National Association of Persons with Psychological Disabilities',
      logo: nappdLogo,
      description: 'National Association of Persons with Psychological Disabilities (NAPPD) is a national association that promotes and advocates for the interests of persons with psychosocial disabilities'
   },
   {
      name: 'CBM Global',
      logo: cbmLogo,
      description: 'CBM Global is an international Christian develelopment organization, commited to improving the quality of life of disabled people living in poor communities around the world',
      website: 'cbm-global.org'
   },
   {
      name: 'Pamumvuri',
      logo: pamumvuriLogo,
      description: 'Pamumvuri is a non-profit organization that focuses on Mental Health and Drug Abuse in Zimbabwe',
      website: 'pamumvuri.org'
   },
];

// helper components
function SectionTitle(props) {
   return <div
      className='primary-text pt-[20pt] font-bold text-2xl ml-4'
   >
      {props.children}
   </div>
}

function PartnerList(props) {
   return <div className=''>
      {
         props.partners.map(partner => {
            return <Partner {...partner} />
         })
      }
   </div>
}


// page component
class UnconnectedPartners extends Page {

   componentDidMount() {
      super.componentDidMount();

      if (!this.props.organizations)
         fetchOrganizations();
   }

   _render() {


      let organizingPartners;

      if (this.props.organizations) {
         organizingPartners = <>
            <PartnerList 
               partners={
                  this.props.organizations
                     .map(org => {

                        const { details: description, id } = org;
                        const logo = organizationIdToImageUrl(id);
                        
                        return {
                           ...org,
                           logo,
                           description,
                        }
                     })
               }
            />
         </>
      } else {
         organizingPartners = <div className='ml-4 font-[Arial]'>
            <p className='text-gray-600'>
               Failed to load trained organizations.
               <Button onClick={fetchOrganizations}>
                  RETRY
               </Button>
            </p>

            
         </div>
      }

      return  <>

         <MetaTags>
            <title>Partners | Quality Rights Zimbabwe</title>
            <meta name="description" content="Here is the list of partners for Quality Rights Zimbabwe: Pamumvuri, CBM Global, World Health Organization (WHO), Ministry Of Health & Child Care, and National Association of Persons with Psychological Disabilities" />
         </MetaTags>

         <SectionTitle>
            PARTNERS
         </SectionTitle>

         <PartnerList partners={partners} />

         <SectionTitle>
            TRAINED ORGANIZATIONS
         </SectionTitle>
         {organizingPartners}
      </>
         
   }
}


const mapStateToProps = state => {
   const { organizations } = state;
   return { organizations }
}

const Partners = connect(mapStateToProps)(UnconnectedPartners);
export default Partners;