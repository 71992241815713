
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import chroma from 'chroma-js';


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);


// helpers
function generateColors(count = 1) {
   return chroma.scale([ '#008DC9', /*'#7D4300',*/ '#C96C00' ]).colors(count);
}


// component
export default function PieChart(props) {

   const labels = [], datasetData = [];
   let total = 0;

   Object
      .keys(props.items)
      .forEach(key => {
         const count = props.items[key];
         labels.push(key);
         datasetData.push(count);

         total += count;
      });


   let pie;

   if (total > 0) {
      const data = {
         labels,
         datasets: [
            {
               data: datasetData,
               borderWidth: 1,
               backgroundColor: generateColors(labels.length)
           },
         ],
      }; 

      pie = <Pie 
         data={data} 
         width={'100%'} 
         height={'100%'}
      />
   } else {
      pie = <div className='h-[200px] vh-align text-gray-500 text-xs font-bold'>
         Nothing to display
      </div>
   }

   return <div className='text-center'>
      <h5>{props.label}</h5>
      <div className='m-[10%]'>
         {pie}
      </div>
   </div>
}