import { Grid, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";


function fromStringToTimestamp(str) {
   const date = new Date(str);
   return date.getTime();
}

function addZeroPlaceHolder(n) {
   if (n > 9)
      return String(n)
   return `0${n}`;
}

function fromTimestampToString(timestamp) {
   const date = new Date(timestamp);
   const month = addZeroPlaceHolder(date.getMonth() + 1);
   const year = date.getFullYear();
   const day = addZeroPlaceHolder(date.getDate());

   return `${year}-${month}-${day}`;
}


export default class DateRangePicker extends Component {

   state = {
      from: fromTimestampToString(0),
      to: fromTimestampToString(Date.now()),
   }

   updateState = (updates) => {
   
      return new Promise((resolve) => {
         this.setState({
            ...this.state,
            ...updates,
         }, resolve);
      });
   }

   async sendOnChangeEvent() {
      
      let { from, to } = this.state;
      from = fromStringToTimestamp(from);
      to = fromStringToTimestamp(to) + 24 * 3600 * 1000 - 1;
      
      if(typeof this.props.onChange === 'function') {
         this.props.onChange({ from, to });
      }

   }

   updateFrom = async (e) => {
      const from = e.target.value;
      await this.updateState({ from });
      this.sendOnChangeEvent();
   }

   updateTo = async (e) => {
      const to = e.target.value;
      await this.updateState({ to });
      this.sendOnChangeEvent();
   }

   componentDidMount() {
      this.sendOnChangeEvent();
   }

   render() {
      return <Grid container>
         <Grid item xs={5}>
            <TextField
               id="txt-date-from"
               label="From"
               type="date"
               InputLabelProps={{
                  shrink: true
               }}
               variant="outlined"
               size="small"
               fullWidth
               value={this.state.from}
               onChange={this.updateFrom}
            />
         </Grid>

         <Grid item xs={2}>
            <div className="fill-container vh-align">
               &mdash;
            </div>
         </Grid>

         <Grid item xs={5}>
            <TextField
               id="txt-date-to"
               label="To"
               type="date"
               InputLabelProps={{
                  shrink: true
               }}
               variant="outlined"
               size="small"
               fullWidth
               value={this.state.to}
               onChange={this.updateTo}
            />
         </Grid>
      </Grid>

   }
}