import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, MenuItem, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from "../toast";
import DateRangePicker from "./DateRangePicker";


export default class BulkProcessor extends Component {

   state = {
      values: {
         mark_as_processed: ''
      },
      from: 0,
      to: Date.now()
   }

   onDateRangeChange = ({ from, to }) => {
      return this.updateState({ from, to });
   }

   submit = async () => {
      
      const { from, to } = this.state;
      const { mark_as_processed } = this.state.values;

      if (typeof mark_as_processed !== 'boolean') {
         errorToast('Provide the status to update the certificates to');
         return document.getElementById('txt-mark-as')
      }

      try {

         showLoading();
         const payload = { payment_processed: mark_as_processed };
         await request.post(`/api/certificates/bulk/payment-processed?from=${from}&to=${to}`, payload);

         if (typeof this.props.reloadCertificates)
            this.props.reloadCertificates();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   render() {
      return <Dialog open={this.props.open}>
         <DialogTitle>Update certificate statuses</DialogTitle>

         <DialogContent>

            <div  style={{ margin: '20px 0' }}>
               <FormLabel>DATE RANGE</FormLabel>
            </div>

            <DateRangePicker
               onChange={this.onDateRangeChange}
            />

            <TextField
               id="txt-mark-as"
               fullWidth
               select
               variant="outlined"
               label="Mark as..."
               value={this.state.values.mark_as_processed}
               onChange={this.onChangeHandlerGenerator('mark_as_processed')}
               size="small"
               style={{
                  margin: '20px auto'
               }}
            >
               <MenuItem value={true}>Processed</MenuItem>
               <MenuItem value={false}>Not Processed</MenuItem>
            </TextField>
         </DialogContent>

         <DialogActions>
            <Button onClick={this.submit} variant="contained">
               SUBMIT
            </Button>
            <Button onClick={this.props.close}>
               CLOSE
            </Button>
         </DialogActions>
      </Dialog>
   }
}