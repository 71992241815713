import Component from "@xavisoft/react-component";
import { connect } from 'react-redux'
import { fetchOrganizations } from "../organizations";
import { Button, Fab, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { hideLoading, showLoading } from "../loading";
import swal from "sweetalert";
import request from "../request";
import actions from "../actions";
import { organizationIdToImageUrl, requestConfirmation } from "../utils";
import OrganizationEditor from "./OrganizationEditor";
import LazyloadImage from './LazyloadImage';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';



function Organization(props) {

   const { id, name, website, details } = props;
   
   let href = website;

   if (href && href.indexOf("http") !== 0)
      href = `//${href}`;

   return <div
      style={{
         borderBottom: '1px solid #ccc', // not working on tailwindcss
      }}
      className="my-1 font-[Arial]"
   >
      <div 
         className="grid grid-cols-[200px,auto] gap-3"
      >
         <LazyloadImage
            aspectRatio={1}
            src={organizationIdToImageUrl(id)}
         />

         <div className="py-2">
            <div className="text-lg font-bold text-gray-600">
               {name}
            </div>

            <p className="text-gray-700 text-sm">
               {details}
            </p>

            <a href={href}>
               {website}
            </a>
         </div>
      </div>

      <div className="text-right">
         <IconButton onClick={props.edit}>
            <EditIcon />
         </IconButton>
         <IconButton color="error" onClick={props.delete}>
            <DeleteIcon />
         </IconButton>
      </div>
   </div>
}


class UnconnectedOrganizatioManager extends Component {

   state = {
      organizationEditorMode: null,
      organizationBeingEdited: null,
   }

   closeOrganizationEditor = () => {
      return this.updateState({ 
         organizationEditorMode: null, 
         organizationBeingEdited: null 
      });
   }


   deleteOrganization = async (organization) => {

      // CONFIRM
      const question = `Delete organization "${organization.name}"?`;
      const confirm = await requestConfirmation({ question });

      if (!confirm)
         return;

      try {

         showLoading();

         const id = organization.id;
         await request.delete(`/api/organizations/${id}`);

         actions.removeOrganization(id);


      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading()
      }
   }

   editOrganization = (organizationBeingEdited) => {
      return this.updateState({
         organizationBeingEdited,
         organizationEditorMode: 'edit',
      });
   }

   componentDidMount() {

      if (!this.props.organizations)
         fetchOrganizations();
   }

   render() {

      let content;
      const { organizations } = this.props;

      if (organizations) {

         let organizationList;

         if (organizations.length > 0) {
            organizationList = organizations.map(organization => {
               return <Organization 
                  {...organization} 
                  delete={() => this.deleteOrganization(organization)}
                  edit={() => this.editOrganization(organization)}
               />
            });

         } else {
            organizationList = <p className="text-gray-600 text-[15px]">
               No organizations added yet.
            </p>
         }

         let organizationEditor;

         if (this.state.organizationEditorMode) {
            organizationEditor = <OrganizationEditor
               close={this.closeOrganizationEditor}
               mode={this.state.organizationEditorMode}
               organization={this.state.organizationBeingEdited}
            />
         }

         content = <div className="fill-container">
            {organizationList}

            <Fab
               className="fixed bottom-[30px] right-[30px]"
               color="primary"
               size="small"
               onClick={() => this.updateState({ organizationEditorMode: 'add' })}
            >
               <AddIcon />
            </Fab>

            {organizationEditor}
         </div>

      } else {
         content = <div
            className="vh-align h-full"
         >
            <div>
               <p style={{ color: 'gray', fontSize: 10 }}>
                  Organizations not loaded. Please try again.
               </p>

               <Button onClick={fetchOrganizations}>
                  RETRY
               </Button>
            </div>
         </div>
      }
      return <div>
         <h3>ORGANIZATIONS</h3>
         {content}
      </div>
   }
}

const mapStateToProps = state => {
   const { organizations } = state || {};
   return { organizations };
}

const OrganizationManager = connect(mapStateToProps)(UnconnectedOrganizatioManager);
export default OrganizationManager;