

import Component from '@xavisoft/react-component';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import logo from '../media/img/logos/logo.png';
import writing from '../media/img/writing.svg';
import { connect } from 'react-redux';


function ContactItem(props) {

	const { icon:Icon, href="#", caption, children } = props;

	return <a href={href} className="valign" style={{ fontSize: 16, color: 'white', textDecoration: 'none', margin: '10px auto' }}>
		<Icon />
		<span style={{ marginLeft: 10 }}>
			{caption}
			{children}
		</span>
	</a>
}


function SocialLink(props) {

	const { href='#', icon:Icon } = props;

	return <a href={href} style={{ color: 'white' }}>
		<Icon style={{ fontSize: 30, margin: 'auto 5px' }} />
	</a>
}


function SocialLinks(props) {

	return <div className="vh-align">
		{props.children}
	</div>
}


class Footer extends Component {

	render() {

		if (this.props.currentRoute === '/dashboard')
			return;

		return <footer>

			<div style={{ background: '#080808', color: 'white', padding: '20px 0' }}>

				<div className="container">

					<Grid container spacing={4}>

                  <Grid item xs={12} sm={6} style={{  textAlign: 'center' }}>


							<img
                        alt=''
                        src={logo}
                        style={{
                           width: '64pt'
                        }}
                     />

							

							<h2 className="center-align">Social Links</h2>

							<SocialLinks>
								<SocialLink icon={FacebookIcon} href="https://www.facebook.com/QualityrightsZW" />
								<SocialLink icon={TwitterIcon} href="https://twitter.com/QualityrightsZW" />
								<SocialLink icon={EmailIcon} href="mailto:info@qualityrights.org.zw" />
							</SocialLinks>
						</Grid>

						<Grid item xs={12} sm={6} className="halign">

                     <div>
                        <h2 className='center-align'>Contact Info</h2>

                        <ContactItem caption="@QualityrightsZW" href="https://twitter.com/QualityrightsZW" icon={TwitterIcon} />
                        <ContactItem caption="info@qualityrights.org.zw" href="mailto:info@qualityrights.org.zw" icon={EmailIcon} />
                        <ContactItem caption="Quality Rights Zimbabwe" icon={FacebookIcon} href="https://www.facebook.com/QualityrightsZW" />
                     </div>

						</Grid>
					</Grid>

				</div>
			</div>

			<div style={{ background: 'black', textAlign: 'center', color: 'white', padding: '20px' }}>
				<span style={{ fontSize: 16, fontFamily: 'sans-serif', paddingBottom: 10, display: 'inline-block' }}>
					&copy; { (new Date()).getFullYear() } QualityRights Zimbabwe
				</span>

				<br />

				<span style={{ fontSize: 20, fontWeight: 'bold', fontFamily: "'Architects Daughter', cursive" }}>
					Website by <a 
                              href="https://xavisoft.co.zw" 
                              style={{ 
                                 textDecoration: 'none', 
                                 // transform: 'rotate(5deg)', 
                                 display: 'inline-block', 
                                 color: 'inherit',
                              }}
                           >
                              <big>
                                 <b>Xavisoft Digital</b>
                              </big>
                              <img src={writing} alt="" style={{ width: 30 }} />
                           </a>
				</span>
			</div>
		</footer>
	}
}

function mapStateToProps(state) {
	const { currentRoute } = state;
	return { currentRoute };
}

export default connect(mapStateToProps)(Footer);