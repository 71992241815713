import { Button } from "@mui/material";
import { HashLink as Link } from 'react-router-hash-link'
import Page from "./Page";


class PageNotFound extends Page {

   _render() {

      return <div 
         style={{
            height: 'calc(var(--window-height) - var(--navbar-height))'
         }}
         className="vh-align"
      >

         <div>
            <h1>PAGE NOT FOUND</h1>
            <p className="grey-text">
               You followed an invalid link.
            </p>

            <Button component={Link} to="/">
               HOMEPAGE
            </Button>
         </div>
      </div>
   }
}

export default PageNotFound;