import { css } from "@emotion/css";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Component from "@xavisoft/react-component";
import { delay } from '../utils'

const dialogCss = css({
   '& .MuiDialog-paper': {
      width: '600px !important',
      aspectRatio: '1/1 !important'
   }
});


class CertificatePreview extends Component {

   loadPDF = async () => {

      // waiting for div to render
      await delay(100);

      const src = `${this.props.file}#toolbar=0;`
      const div = document.getElementById('div-certificate-pdf');

      if (!div)
         return;

      div.innerHTML = '';

      const embed = document.createElement('embed');
      embed.src = src;
      embed.height = 'auto';
      embed.width = '100%';
      embed.style.aspectRatio = 1;



      div.append(embed);
   }

   componentDidUpdate(prevProps) {

      if (this.props.open && !prevProps.open) {
         this.loadPDF();
      }
   }

   render() {

      const props = this.props

      return <Dialog open={props.open} className={dialogCss}>

         <DialogTitle>CERTIFICATE</DialogTitle>

         <DialogContent>
            <div id="div-certificate-pdf" className="fill-container" />
         </DialogContent>

         <DialogActions>
            <Button onClick={this.loadPDF}>
               RETRY
            </Button>
            <Button onClick={props.close}>
               CLOSE
            </Button>
         </DialogActions>
      </Dialog>
   }

}

export default CertificatePreview;