

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import clickCertificates from '../media/img/instructions/get-link/certificates.png';
import clickRewards from '../media/img/instructions/get-link/rewards.png';
import clickSeeCertificate from '../media/img/instructions/get-link/certificate.png';
import rightClickDownload from '../media/img/instructions/get-link/download.png';
import clickCopyLink from '../media/img/instructions/get-link/copy.png';



const steps = [
   {
      text: 'Login to the etraining platform'
   },
   {
      text: 'Go to "Certifications"',
      image: clickCertificates
   },
   {
      text: 'Click "See my rewards"',
      image: clickRewards,
   },
   {
      text: 'Click "SEE CERTIFICATE"',
      image: clickSeeCertificate,
   },
   {
      text: 'Right click "DOWNLOAD" (long press if you\'re on a touch screen device)',
      image: rightClickDownload
   },
   {
      text: 'Click "Copy Link address" (or "copy link", depending on your browser)',
      image: clickCopyLink,
   },
   {
      text: 'Go and paste the link',
   }
];

function Step(props) {
   return <div 
         style={{
            marginBottom: 40,
            borderBottom: '2px grey solid',
            paddingBottom: 40,
         }}
      >
      <h3>{props.number}. {props.text}</h3>
      <img
         style={{
            width: '100%'
         }}
         src={props.image}
         alt=""
      />
   </div>
}


function CertificateLinkInstructions(props) {

   return <Dialog open={props.open}>

      <DialogTitle><h1>INSTRUCTIONS</h1></DialogTitle>

      <DialogContent>
         {
            steps.map((step, number) => {
               return <Step
                  number={number + 1}
                  text={step.text}
                  image={step.image}
               />
            })
         }
      </DialogContent>

      <DialogActions>
         <Button onClick={props.close}>
            CLOSE
         </Button>
      </DialogActions>
   </Dialog>
}


export default CertificateLinkInstructions;